<template>
  <b-row v-if="loading">
    <b-col sm="12">
      <b-card no-body class="card-revenue-budget">
        <b-card-header>
          <b-card-title>ข้อมูลการสมัครสมาชิก</b-card-title>
          <b-card-text class="mr-25 mb-0">
            <!-- Updated 1 month ago -->
          </b-card-text>
        </b-card-header>
        <vue-apex-charts id="budget-chart1234" height="310" type="bar" :options="_options" :series="_series" />
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BCard, BRow, BCol, BDropdown, BDropdownItem, BButton } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'
import { mapState } from 'vuex'
// import Dayjs from 'vue-dayjs'
// const dayjs = Dayjs
export default {
  components: {
    DashboardThreeOp1: () => import('./DashboardThreeOp1'),
    DashboardThreeOp2: () => import('./DashboardThreeOp2'),
    VueApexCharts,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapState('dashboardDataStore', ['results', 'loading']),
    _series() {
      const result = this.results?.queryMonthRegisterChart
      return [
        {
          name: 'ผู้ใช้ใหม่ทั้งหมด',
          data: Object.keys(result).map(x => result[x]),
        },
      ]
    },

    _options() {
      const self = this

      return {
        chart: {
          type: 'bar',
        },
        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },

        xaxis: {
          categories: Object.keys(self.results?.queryMonthRegisterChart),
          labels: {
            formatter: function(value, timestamp, opts) {
              return self.gFormatDate(value)
            },
          },
        },
        labels: {
          style: {
            fontSize: '12px',
          },
        },
      }
    },
  },
}
</script>
